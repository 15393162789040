import React from "react";
import { Route, Routes, useLocation } from 'react-router-dom';
import { AnimatePresence } from "framer-motion"

import Home from '../pages/web/home';

function App() {
  const location = useLocation();

  return (
      <AnimatePresence>
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<Home />} />
        </Routes>
      </AnimatePresence>
  );
}

export default App;
