import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

import "./pages/index.css"
import "./pages/montserrat.css"

import AnimatedRoutes from "./components/routes";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
        <AnimatedRoutes />
      </Router>
  </React.StrictMode>
);

